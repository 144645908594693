<template>
    <footer class="footer">
        <div class="footer__bg"></div>
        <div class="footer__top">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="logo">
                            <img src="@/assets/images/logo.png" :alt="img_alt">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="footer-links">
                            <a :href="base_url">Home</a>
                            <a href="terms-and-condition" target="_blank">Terms & Conditions</a>
                            <a href="privacy-policy" target="_blank">Privacy Policy</a>
                            <a href="mailto:support@teds-woodworkings.com">Contact</a>
                        </div>
                        <div class="footer__bottom-text">
                            <p>&copy;. All Rights Reserved. <span class="primary">Ted's Woodworking</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
      base_url: this.$base_url,
      img_alt: this.$img_alt,
    }
  },
}
</script>