<template>

    <div class="page-wrapper">


      <Header/>


        
      <section id="slider" class="main-slider">
        <div class="container">
          <div class="main-slider-text">
            <div class="row">
              <div class="col-12 col-xl-5 order-1">
                <div class="d-flex justify-content-center">
                  <img src="@/assets/images/teds-woodworking.png" :alt="img_alt" class="img-fluid">
                </div>
              </div>
              <div class="col-12 col-xl-7 order-2">
                <h1>Ted's Woodworking</h1>
                <p class="author">By Ted Mcgrath</p>
                <h2>World's Largest Collection Of</h2>
                <h3>16,000 Wood Plans</h3>
                <h4>One-Stop Portal For A Lifetime of Woodworking Plans</h4>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section class="cta">
        <div class="container">
          <div class="cta__wrapper">
            <div class="row">
              <div class="col-12 col-md-8 col-lg-9">
                <h3>Get Instant Access To Entire <span class="primary">Ted's Woodworking</span> 16,000 Wood Plans Today...</h3>
                <p>+Plus <b class="primary">All Exclusive Bonuses</b> for FREE!</p>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="d-flex justify-content-end">
                  <a @click="order();" href="javascript:;">
                    <h3><span>Now Only</span>$67</h3>
                  </a>
                  <!--<p class="regular-price"><strike>Regular Price: $67</strike></p>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section class="about">
        <div class="container">
          <div class="row">
              <div class="col-xl-7">
                <div class="video-container">
                  <iframe src="https://www.youtube.com/embed/QX5cJXnFFrE?autoplay=0&controls=0&rel=0&modestbranding=1&showinfo=0&wmode=transparent&playsinline=0&enablejsapi=1&origin=https%3A%2F%2Fwww.tedswoodworking.com&widgetid=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
              <div class="col-xl-5">
                  <div class="about__content">
                      <div class="sec-title">
                          <span class="sec-title__tagline">Teds Woodworking</span>
                          <h2 class="sec-title__title">Make Woodworking <span class="primary">Simple Again</span></h2>
                      </div>

                      <div class="about__content-text">
                          <p>Teds Woodworking is created for one purpose: to give you the best chance of completing your project quickly and perfectly, the first (or next) time you try it.</p>
                          <p>Make woodworking easy and effortless with step by step plans, detailed instructions and blown-up schematics.</p>
                          <p>Say goodbye to your woodworking struggles and confusion - get the freedom to build anything you want out of 16,000 different projects!</p>
                      </div>

                  </div>
              </div>
          </div>
        </div>
      </section>


      
      <section class="why">
        <div class="container">
          <div class="row">
            <div class="col-xl-6">
              <div class="sec-title">
                <h2 class="sec-title__title">Why <span class="primary">Teds Woodworking</span>?</h2>
                <h3 class="mb-3">Make <b>16,000 Projects</b> With Step By Step Plans</h3>
                <h3 class="mb-3">Even if you don’t have a large workshop or expensive tools!</h3>
                <p>Each of the 16,000 projects are detailed enough to leave nothing to guesswork yet simple enough for beginners. </p>
              </div>
              <ul class="checked">
                <li>
                  <h3>Step-By-Step Instructions</h3>
                  <p>Each Ted’s Woodworking plan takes you from A to Z, helping you complete projects that are super easy and super fun!</p>
                </li>
                <li>
                  <h3>Cutting and Materials Lists</h3>
                  <p>Ted’s Woodworking provides exact materials lists and cutting directions for each project to help you save time and cut down on waste.</p>
                </li>
                <li>
                  <h3>Detailed Schematics</h3>
                  <p>The sharp and colorful schematics that are included in each plan, means no guesswork and makes each project a joy to complete.</p>
                </li>
                <li>
                  <h3>Views From All Angles</h3>
                  <p>Take a detailed look at every angle, every corner, and every joint of your finished project before you start building.</p>
                </li>
                <li>
                  <h3>Suitable For Beginners and Professionals Alike</h3>
                  <p>Whether you’re a seasoned woodworker or an absolute novice, Ted’s Woodworking has the perfect plan for you.</p>
                </li>
              </ul>
            </div>
            <div class="d-none d-xl-block col-xl-6">
              <div class="why1"></div>
              <div class="why2"></div>
              <div class="why3"></div>
              <div class="why4"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="projects">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="projects__top">
                        <div class="sec-title">
                            <span class="sec-title__tagline">Teds Woodworking</span>
                            <h2 class="sec-title__title">See What <span>Our Customers Have Built</span> With Our Plans</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="auto-container">
          <div class="row">
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/01.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/02.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/03.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/04.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/05.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/06.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/07.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/08.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/09.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/10.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/11.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-2">
              <div class="projects__single">
                <img src="@/assets/images/projects/12.jpg" :alt="img_alt" />
              </div>
            </div>
          </div>
          <div class="text-center">
            <p class="mt-2 mb-0 text-muted"><small><em>Disclaimer: Individual results may vary.</em></small></p>
            <h4 class="fs-3 font-weight-bold p-4">And many more projects...</h4>
          </div>
        </div>
      </section>


      <section class="getting">
        <div class="getting__img">
          <a @click="order();" href="javascript:;" class="overlay-content text-center">
            <p>Now Only</p>
            <h2>$67</h2>
            <h3>+All Bonuses</h3>
          </a>
        </div>
        <div class="container">
          <div class="row">
              <div class="d-none d-xl-block col-xl-6">
              </div>
              <div class="col-12 col-xl-6">
                  <div class="getting__content">
                      <div class="sec-title">
                          <h2 class="sec-title__title">What You Are Getting?</h2>
                      </div>
                      <ul class="checked">
                        <li>
                          <h3>Ted's Woodworking Plans</h3>
                          <p>Instant Access To All 16,000 Projects with step-by-step details, plans, cutting & materials list and more...</p>
                        </li>
                        <li>
                          <h3>Lifetime FREE Monthly Plans</h3>
                          <p>No recurring fees or other charges</p>
                        </li>
                        <li>
                          <h3>A Treasure Trove of Tutorials</h3>
                          <p>And in-depth woodworking training</p>
                        </li>
                        <li>
                          <h3>Free! Bonus #1 <span>($47 Value)</span></h3>
                          <p><b>DWG & CAD Plan Viewer</b> - So you can edit and modify your plans</p>
                        </li>
                        <li>
                          <h3>Free! Bonus #2 <span>($77 Value)</span></h3>
                          <p><b>150 Premium Woodworking Videos</b> - Covering a wide range of topics, hosted by master craftsman</p>
                        </li>
                        <li>
                          <h3>Free! Bonus #3 <span>($27 Value)</span></h3>
                          <p><b>How To Start A Woodworking Business</b> - Learn how to make money selling your custom woodwork</p>
                        </li>
                        <li>
                          <h3>Free! Bonus #4 <span>($39 Value)</span></h3>
                          <p><b>Complete Woodworking Guides</b> - Over 200 pages of tips and tricks with detailed drawings and diagrams</p>
                        </li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>
        </section>

      

      <section class="buy">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <h2 class="sec-title__subtitle fs-1 font-weight-bold mb-3 primary">Teds Woodworking 16,000 Plans & All Exclusive Bonuses</h2>
                <h3 class="sec-title__title mb-2">Order Now & Start Building In 5 Minutes!</h3>
              </div>
            </div>
          </div>
          <div class="buy-box">
            <div class="row">
              <div class="col-12 col-lg-7">
                <img src="@/assets/images/teds-woodworking2.png" :alt="img_alt" class="img-fluid">
              </div>
              <div class="col-12 col-lg-5">
                <h3>Special Price Today!</h3>
                <h4>Now Only $67</h4>
                <a @click="order();" href="javascript:;" target="tar1" rel="nofollow">
                  <img src="@/assets/images/buy.svg" :alt="img_alt">
                </a>
                <!--<p class="regular-price"><strike>Regular Price $67</strike></p>-->
                <p class="mb-0"><small>60-Day Money Back Guarantee. 100% Satisfaction.</small></p>
                <p><small>This is a one-time purchase. There are NO monthly fees.</small></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-10 col-xl-8 mx-auto my-2">
              <img src="@/assets/images/cbcards.jpg" :alt="img_alt" class="img-fluid">
            </div>
          </div>
          <div class="notes">
            <p><b>CLICKBANK</b> online payment system is safe and secure. You can pay with either VISA, MasterCard or AMEX.</p>
            <p>All communications between you and the payment server are protected and encrypted by SSL.</p>
            <p>This guarantees that the information you inputted cannot be intercepted, providing you with peace of mind and security while making a payment.</p>
          </div>
          <div class="text-center">
            <p><small class="font-weight-normal text-muted"><em>(Example of Teds Woodworking Members Hub screen)</em></small></p>
            <p><img src="@/assets/images/access.png" :alt="img_alt" class="img-fluid"></p>
          </div>
          <ul class="info">
            <li>The moment you place the order, you'll be taken to a <b>Secure Checkout</b> page.</li>
            <li>Please fill in your payment details by using a <b>Credit Card</b>.</li>
            <li>After you fill in your information and confirm, you'll be able to get an email that gives you the download access.</li>
            <li>You can download the entire <b>Teds Woodworking 16,000 plans package</b> and all the bonuses right away. Plus you'll get the option of getting everything on DVDs.</li>
          </ul>
        </div>
      </section>



      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#141212">
          <path d="M0 100 C -10 0 50 0 150 100 Z"></path>
      </svg>

      <section class="money-back">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="money-badge">
                <img src="@/assets/images/money-back.png" :alt="img_alt" class="img-fluid">
              </div>
            </div>
            <div class="col-xs-12 col-lg-9">
              <h3><span>60-Day</span> 100% Money Back Guarantee</h3>
              <p>If you act now, your satisfaction is absolutely assured. Thanks to the Ted’s Woodworking ironclad 100% Satisfaction Guarantee, you have a full 60 days to review the program with no obligation whatsoever.</p>
              <p>If you aren’t convinced that this is the most complete and effective woodworking resource you’ve ever seen, you can receive every penny of your purchase price back with no questions asked.</p>
            </div>
          </div>
        </div>
      </section>

        
      
      <Footer/>

    </div>
</template>



<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hop_url: this.hop_url,
      base_url: this.base_url,
      img_alt: this.img_alt
    }
  },
  methods: {
    order () {
      var tar = 'tar1';
      var url = 'order/get-discount';
      window.open(process.env.VUE_APP_HOP_URL, tar);
      setTimeout(function(){ window.open(url, tar) }, 1500);
    },
  }
}
</script>