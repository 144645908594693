<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted: function() {
    document.oncontextmenu = function() {
      return false;
    }

    /*
    fetch(process.env.VUE_APP_API_IP)
    .then(response => response.json())
    .then(response => {
      this.$clientIP = response.ip;

      if( this.$clientIP ) {

        this.$domain = this.$base_url.replace("https://", '');

        this.$current_url = ( window.location.pathname === '/' ) ? this.$base_url : this.$base_url + window.location.pathname;

        this.$api_url = process.env.VUE_APP_SPY_API+'?ip='+this.$clientIP+'&domain='+this.$domain+'&current_url='+this.$current_url+'&refer_url='+document.referrer;

        fetch(this.$api_url, {
          method: 'GET',
        });
      }
    })
    */

  }
}
</script>