<template>
  <header class="main-header clearfix">
    <div class="main-header__wrapper">
      <div class="container">
        <div class="main-header__inner">
          <div class="main-header__left">
            <div class="logo">
              <a :href="base_url"><img src="@/assets/images/logo.png" :alt="img_alt" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      base_url: this.$base_url,
    }
  },
}
</script>