import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 400,
  offset: -100,
})

Vue.prototype.$base_url = process.env.VUE_APP_BASE_URL
Vue.prototype.$seo_title = process.env.VUE_APP_SEO_TITLE
Vue.prototype.$hop_url = process.env.VUE_APP_HOP_URL
Vue.prototype.$img_alt = process.env.VUE_APP_IMG_ALT

import "./plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/css/style.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: function() {
    if( process.env.NODE_ENV == 'production' && location.host != process.env.VUE_APP_URL_NAME ) {
      document.getElementById('app').remove();
    }
  }
}).$mount("#app");
