import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Home from "../views/Home.vue";
import Order from "../views/Order.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/terms-and-condition",
    name: "Terms",
    component: Terms
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/order/get-discount",
    name: "Order",
    component: Order
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
